// Generated by Framer (eca4804)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["z4zJmpBXv", "juwBdYyMZ", "edb8qgAqf"];

const serializationHash = "framer-0DOw7"

const variantClassNames = {edb8qgAqf: "framer-v-1vziiss", juwBdYyMZ: "framer-v-h5hro6", z4zJmpBXv: "framer-v-1uzgpa8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.4, ease: [0.19, 1, 0.22, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Click to copy": "juwBdYyMZ", Copied: "edb8qgAqf", Main: "z4zJmpBXv"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "z4zJmpBXv"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z4zJmpBXv", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1uzgpa8", className, classNames)} data-framer-name={"Main"} layoutDependency={layoutDependency} layoutId={"z4zJmpBXv"} ref={refBinding} style={{backgroundColor: "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({edb8qgAqf: {"data-framer-name": "Copied"}, juwBdYyMZ: {"data-framer-name": "Click to copy"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNDAw", "--framer-font-family": "\"Aspekta 400\", \"Aspekta 400 Placeholder\", sans-serif", "--framer-font-size": "19px", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-38e83481-a340-4535-b223-ac61fd3243f4, rgb(255, 255, 255)))"}}>Click to copy</motion.p></React.Fragment>} className={"framer-2w705l"} data-framer-name={"Click to copy"} fonts={["CUSTOM;Aspekta 400"]} layoutDependency={layoutDependency} layoutId={"QbO2GSfvv"} style={{"--extracted-r6o4lv": "var(--token-38e83481-a340-4535-b223-ac61fd3243f4, rgb(255, 255, 255))"}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({edb8qgAqf: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNDAw", "--framer-font-family": "\"Aspekta 400\", \"Aspekta 400 Placeholder\", sans-serif", "--framer-font-size": "19px", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-38e83481-a340-4535-b223-ac61fd3243f4, rgb(255, 255, 255)))"}}>Copied!</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0DOw7.framer-jourhx, .framer-0DOw7 .framer-jourhx { display: block; }", ".framer-0DOw7.framer-1uzgpa8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-0DOw7 .framer-2w705l { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0DOw7.framer-1uzgpa8 { gap: 0px; } .framer-0DOw7.framer-1uzgpa8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-0DOw7.framer-1uzgpa8 > :first-child { margin-left: 0px; } .framer-0DOw7.framer-1uzgpa8 > :last-child { margin-right: 0px; } }", ".framer-0DOw7.framer-v-h5hro6.framer-1uzgpa8, .framer-0DOw7.framer-v-1vziiss.framer-1uzgpa8 { height: min-content; padding: 10px 16px 10px 16px; width: min-content; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 1
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"juwBdYyMZ":{"layout":["auto","auto"]},"edb8qgAqf":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerLeOx79_L4: React.ComponentType<Props> = withCSS(Component, css, "framer-0DOw7") as typeof Component;
export default FramerLeOx79_L4;

FramerLeOx79_L4.displayName = "Click to copy";

FramerLeOx79_L4.defaultProps = {height: 1, width: 1};

addPropertyControls(FramerLeOx79_L4, {variant: {options: ["z4zJmpBXv", "juwBdYyMZ", "edb8qgAqf"], optionTitles: ["Main", "Click to copy", "Copied"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLeOx79_L4, [{explicitInter: true, fonts: [{family: "Aspekta 400", source: "custom", url: "https://framerusercontent.com/assets/AEfYlIhKbMvfYJjz64K4roBkv1k.woff2"}]}], {supportsExplicitInterCodegen: true})